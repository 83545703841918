import React from 'react';
import { useDropzone } from "react-dropzone";
var FileSaver = require('file-saver');

const Home = () => {
  const readFiles = (files) => Promise.all(files.map(f => {

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async (e) => {
        const text = e.target.result;
        resolve(text);
      };

      reader.readAsText(f);
    });
  }))

  const parseContent = (content) => {
    const lines = content.split('\n')
    let pageIdx = 0
    let parsed = []

    lines.map(tmp => {
      const line = tmp.trim()

      if (line === '') return 0

      if ((/^page\s+[0-9]+/gi).test(line)) {
        const splitted = line.split(' ')
        pageIdx = Number(splitted[splitted.length - 1])
      }

      let page = parsed.find(e => e.page === pageIdx)

      if (!page) {
        parsed = [...parsed, { page: pageIdx, lines: [] }]
        page = parsed.find(e => e.page === pageIdx)
      }
      page.lines.push(line)
      return 0
    })

    return parsed
  }

  const processFiles = async files => {
    const texts = await readFiles(files);
    console.log(texts);

    const fileContent = texts[0]
    let output = ''
    const parsed = parseContent(fileContent)

    const formatted = parsed.map(page => {
      return page.lines.map((line, idx) => {
        if (idx === 0) return ''
        return `${page.page}.${line}`
      })
    })

    formatted.forEach(page => {
      output += page.join('\n')
    })

    var blob = new Blob([output], { type: "text/plain;charset=utf-8" });
    FileSaver.saveAs(blob, `output-${+ new Date()}.txt`);
  };

  const { getRootProps, getInputProps, acceptedFiles, isDragActive } = useDropzone({ onDrop: processFiles });

  const files = acceptedFiles.map((file) =>
  (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));


  return (
    <div className='h-full w-full flex-row justify-center relative px-2'>
      <div>
        <h2 className="text-base text-coolerOrange font-semibold tracking-wide uppercase">This website can be updated with new tools for Adobe softwares</h2>
        <h3 className=" mb-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-4xl">
          Document formatter for LettererBuddy
        </h3>
      </div>
      <div className="my-4">
        <div>
          <p>Document formatter allows to make some translation documents formats compatible with the InDesign script <a className="text-coolerOrange underline" href='https://github.com/saraoswald/Letterer-Buddy-Multicolumn' target='_blank' rel='noreferrer'>Letterer Buddy Multicolumn</a>.</p>
          <p>Currently, only one input format is supported, feel free to contact me on Twitter or by mail so I can add support for your format.</p>
          <p className='pt-2'><span className='font-bold'>Warning:</span> You need to copy the content of your doc/docx file inside a .txt file. The formatter don't support anything else than text file. <br />If the output file isn't automatically downloaded, there is maybe a rate limit: Try to split your file in two files.</p>
        </div>
        <div className='h-full w-full flex flex-row space-x-8'>
          <div className=''>
            <p className='py-2'>Input example:</p>
            <pre className='bg-slate-100 dark:bg-slate-900 p-4 rounded text-sm w-full xl:pr-24'>
              <p>Page 001</p>
              <p>1    text</p>
              <p>2    text</p>
              <p>3    ...</p>
              <p>Page 002</p>
              <p>1    text</p>
              <p>2    text</p>
              <p>3    ...</p>
              <p>Page ...</p>
            </pre>
          </div>
          <div>
            <p className='py-2'>Output example:</p>
            <pre className='bg-slate-100 dark:bg-slate-900 p-4 rounded text-sm w-full xl:pr-24'>
              <p>001.1    text</p>
              <p>001.2    text</p>
              <p>001.3    ...</p>
              <p>002.1    text</p>
              <p>002.2    text</p>
              <p>002.3    ...</p>
              <p>...</p>
              <p>...</p>
              <p>...</p>
            </pre>
          </div>
        </div>
      </div>
      <div className='w-full'>
        <div {...getRootProps({ className: "dropzone" })}>
          <input className="input-zone" {...getInputProps()} />
          <div className="flex justify-center w-full xl:h-52 h-36 px-2 transition duration-500 border-2 border-black dark:border-white border-dashed rounded-md appearance-none cursor-pointer hover:border-gray-400 focus:outline-none">
            <div className="text-center h-full w-full flex justify-center relative">
              <span className="flex items-center space-x-2">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 dark:text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                </svg>
                {isDragActive ? (
                  <p className="dropzone-content font-medium dark:text-white">
                    Release to drop the text file here
                  </p>
                ) : (
                  <p className="dropzone-content font-medium dark:text-white">
                    Drop here your document as .txt file, or
                    <span> </span>
                    <span className="text-blue-600 underline">browse</span>
                  </p>
                )}
                <aside>
                  <ul>{files[0]}</ul>
                </aside>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Home;
