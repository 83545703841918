import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from './pages/Home/Home';
import NotFound from './pages/NotFound';

const Router = () => (
  <Switch>
    <Route path="/" exact component={Home} />
    <Route path="*" component={NotFound} />
  </Switch>
);

export default Router;
