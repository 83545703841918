import React, { useContext } from 'react';
import ThemeContext from '../contexts/themeContext';
import Moon from '../images/Moon';
import Sun from '../images/Sun';
import Picto from '../images/Picto';

const Footer = () => {
  const themeContext = useContext(ThemeContext);
  return (
    <footer className="h-10 md:pt-8 flex items-center justify-evenly md:justify-auto">
      <div className="flex md:mx-4 w-full justify-between">
        <div className="flex items-center">
          <Picto className="h-4 mx-2 md:mr-10 transition duration-500 fill-black dark:fill-white" />
          <div className="hidden: sm:flex items-center">
          <a href="https://nohit.studio" target="_blank" rel="noreferrer" className="hidden xl:block">
            <span className="mr-8 hidden md:block">
              {`© ${new Date().getFullYear()} NO HIT STUDIO`}
            </span>
            </a>
            <a href="https://twitter.com/nohitstudio" target="_blank" rel="noreferrer" className="hidden mr-8 xl:block">Twitter</a>
            <a href="https://github.com/Loriage/Adobe-Scripts" target="_blank" rel="noreferrer" className="hidden mr-8 xl:block">Github</a>
            <a href="mailto:bruno@nohit.studio" target="_blank" rel="noreferrer" className="hidden mr-12 xl:block">Mail</a>
          </div>
        </div>
        <div className="flex">
          <button
            className="mr-2 focus:outline-none"
            type="button"
            onClick={() => themeContext.changeTheme()}
          >
            {themeContext.theme === 'dark'
              ? <Sun className="h-5" />
              : <Moon className="h-5" />}
          </button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
